<template>
  <div>
    <ListApproveDriver></ListApproveDriver>
  </div>
</template>

<script>
import ListApproveDriver from '@core/layouts/components/admin-components/ListApproveDriver.vue'
export default {
  name: 'ApproveDrivers',
  components: {
    ListApproveDriver,
  },
}
</script>

<style scoped>
</style>
